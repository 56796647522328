
    import Vue from "vue";
    import Component from "vue-class-component";
    import ApiButton from "@/components/ApiButton.vue";
    import Details from "@/components/BuyerZoneSupplierTabs/Details.vue";
    import Contacts from "@/components/BuyerZoneSupplierTabs/Contacts.vue";
    import Communications from "@/components/BuyerZoneSupplierTabs/Communications.vue";
    import Payments from "@/components/AdminSupplierTabs/Payments.vue";
    import SupplierModules from "@/components/SupplierModulesTab.vue";
    import RegionDocuments from "@/components/BuyerZoneSupplierTabs/RegionDocuments.vue";
    import HmrcAndBanking from "@/components/BuyerZoneSupplierTabs/HmrcAndBanking.vue";
    import ModuleContent from "@/components/ModuleContent.vue";
    import Certificates from "@/components/AdminSupplierTabs/Certificates.vue";
    import Users from "@/components/AdminSupplierTabs/Users.vue";
    import store from "@/store/store";
    import router, { beforeRouteLeave } from "@/router/router";
    import apiClient from "@/stuff/ApiClient";
    import utils from "@/stuff/Utils";
    import { Buyer as ModelBuyer } from "@/model/Buyer";
    import { Supplier as ModelSupplier } from "@/model/Supplier";
    import { SupplierTabCounts } from "@/model/SupplierTabCounts";
    import { UserRole } from "@/model/Enums";

    @Component({
        components: { 
            ApiButton, 
            Details,
            Contacts, 
            Communications, 
            Payments,
            SupplierModules, 
            RegionDocuments, 
            Certificates,
            HmrcAndBanking,
            ModuleContent,
            Users
        },
        beforeRouteLeave
    })
    export default class Supplier extends Vue {

        async mounted() {
            this.buyerID = store.state.signedInUser == null ? 0 : +store.state.signedInUser.buyerID
            this.supplierID = +this.$route.params.supplierID;
            await Promise.all([
                store.dispatch("loadCompanyTypeList"),
                store.dispatch("loadCountryList"),
                store.dispatch("loadTradeList"),
                this.load(this.supplierID),
                this.loadBuyer(),
                this.loadTabCounts()
            ]);

            this.load(this.supplierID);
        }

        checkUrlQueryTab() {
            const query = Object.assign({}, this.$route.query);
            const communicationID = query.communicationID;
            if(communicationID != null) {
                delete query.communicationID;
                this.$router.replace({ query });
                this.tabIndex = 2;
            }
        }

        //
        // -- properties
        //

        tabIndex: number = 0;
        buyerID: number = 0;
        supplierID: number = 0;       
        readonly supplier = new ModelSupplier();
        readonly buyer = new ModelBuyer();
        readonly tabCounts = new SupplierTabCounts();
        
        // computed
        get title(): string {
            if (!this.supplier) return "- - -";
            if (utils.isEmptyId(this.supplier.id)) return "New Supplier"; //this shouldn't happen!
            return this.supplier.name ? this.supplier.name : "(No supplier name)";
        }

        get canSeeHmrc(): boolean { 
            const user = store.state.signedInUser;
            return user?.role === UserRole.Admin || 
                user?.role === UserRole.Cqms || 
                (user?.role === UserRole.Buyer && user?.isBuyerHmrcAccess); 
        }

        //
        // -- methods
        //

        async onTabActivated(newTabIndex: number) {
            console.log(`*** onTabActivated this.tabIndex=${this.tabIndex} newTabIndex=${newTabIndex}`);
        }

        goToSuppliers() {
            // show supplier search (we could go back - but no guarantee that we came from search)
            // TODO - maybe check history and go back?
            router.push("/buyerZone/suppliers");
        }

        async load(supplierID: number) {
            if(supplierID > 0) {
                const serverSupplierData = await apiClient.get(`api/supplier/Load?id=${supplierID}&buyerID=${this.buyerID}`);
                utils.resetObject(this.supplier);
                this.supplier.update(serverSupplierData);
                this.checkUrlQueryTab(); // go off to cummunicatios tab ?
            }
            else {
                utils.resetObject(this.supplier);
            }
        }

        async loadBuyer() {
            utils.resetObject(this.buyer);
            const serverBuyerData = await apiClient.get(`api/buyer/Load?id=${this.buyerID}`);
            this.buyer.update(serverBuyerData);
        }

        async loadTabCounts() {
            const data = await apiClient.get(`/api/supplier/tabCounts?supplierID=${this.supplierID}&buyerID=${this.buyerID}`);
            this.tabCounts.update(data);
        }

    }
