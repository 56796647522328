
    import Vue from "vue";
    import Component from "vue-class-component";
    import { Prop } from "vue-property-decorator";
    import store from "@/store/store";
    import apiClient from "@/stuff/ApiClient";
    import utils from "@/stuff/Utils";
    import { ILookupItem } from "@/model/LookupItem";
    import { Supplier as ModelSupplier } from "@/model/Supplier";
    import { RegionSupplierSearchParameters } from "@/model/RegionSupplierSearchParameters";
    import { TriState } from "@/model/Enums";
    import { Authentication } from "@/stuff/Authentication";
    import { IUser } from "@/model/User";
    import { IRegionSupplier, RegionSupplier } from "@/model/RegionSupplier";
    import { RegionLookupItem } from "@/model/RegionLookupItem";
    import { SiteSearchParameters } from "@/model/SiteSearchParameters";
    import { ISite } from "@/model/Site";
    import * as toastr from "toastr";
    import ApiButton from "@/components/ApiButton.vue"

    @Component({ components: { ApiButton } })
    export default class Details extends Vue {

        async mounted() {
            store.dispatch("loadCompanyTypeList");
            store.dispatch("loadCountryList");
            store.dispatch("loadTradeList");

            this.buyerID = (Authentication.signedInUser() as IUser).buyerID;

            await this.loadRegionList();
            await this.loadSiteList();

            this.load(this.supplierID);
            this.refreshRegions();
        }

        @Prop({ required: true })
        private supplierID!: number;
        private buyerID: number = 0;

        private readonly supplier = new ModelSupplier();
        private regionSupplierList: Array<IRegionSupplier> = [];

        private readonly regionSupplier = new RegionSupplier();

        private get companyTypeList(): Array<ILookupItem> { return this.$store.state.companyTypeList; }
        private get companyTypeOptions(): Array<ILookupItem> { return utils.selectOptions(this.$store.state.companyTypeList); }

        private get countryList(): Array<ILookupItem> { return this.$store.state.countryList; }
        private get countryOptions(): Array<ILookupItem> { return utils.selectOptions(this.$store.state.countryList); }

        private get tradeList(): Array<ILookupItem> { return this.$store.state.tradeList; }
        private get tradeOptions(): Array<ILookupItem> { return utils.selectOptions(this.$store.state.tradeList); }
        
        private regionList: Array<RegionLookupItem> = [];

        private siteList: Array<ISite> = [];
        private get siteOptions(): Array<ISite> { 
            return this.siteList.filter(s => s.id > 0 && !utils.hasDateValue(s.deleted) && s.regionID === this.regionSupplier.regionID);
        }

        private get title(): string {
            return this.supplier.id ? this.supplier.name : "Supplier";
        }

        private get companyTypeText(): string {
            return utils.lookupDescription(this.supplier.companyTypeID, this.companyTypeList);
        }

        private get countryText(): string {
            return utils.lookupDescription(this.supplier.countryID, this.countryList);
        }

        private get tradesText(): string {
            let text = "";
            for(const id of this.supplier.tradeIDs) {
                const item = this.tradeList.filter(i => i.id === id)[0];
                if(text) text += "\n";
                text += (item ? item.description : "- - -");
            }
            return text || "(no trades)";
        }

        async loadSiteList() {
            const parms = new SiteSearchParameters();
            parms.buyerID = this.buyerID;
            this.siteList = await apiClient.post("/api/site/search", parms);
        }  

        sitesHtml(regionSupplier: RegionSupplier) {
            if(regionSupplier.siteIDs.length === 0) return "";
            let html = "";
            for(const siteID of regionSupplier.siteIDs) {
                const site = this.siteList.find(s => s.id === siteID);
                const x = site ? site.description : "- - -";
                if(html) html += "<br>"
                html += x;
            }
            return html;
        }

        async loadRegionList() {
            this.regionList = await apiClient.get("api/region/lookups");
        }

        async load(supplierID: number) {
            if(supplierID > 0) {
                const serverSupplierData = await apiClient.get(`api/supplier/Load?id=${supplierID}&buyerID=${this.buyerID}`);
                utils.resetObject(this.supplier);
                // nb - update maps suppliers and contacts too
                this.supplier.update(serverSupplierData);
            }
            else {
                utils.resetObject(this.supplier);
            }
        }

        async refreshRegions() {
            const parameters = new RegionSupplierSearchParameters();
            parameters.supplierID = this.supplierID;
            parameters.buyerID = this.buyerID;
            parameters.isDeleted = TriState.False;
            const responseData = await apiClient.post("api/regionSupplier/search", parameters);
            this.regionSupplierList = responseData.list.map((r: IRegionSupplier) => new RegionSupplier(r));
        }

        private async editRegion(regionData: IRegionSupplier) {
            this.regionSupplier.update(regionData);
            // show the dialogue
            this.$bvModal.show("regionDialogue");
            // refresh from server
            const serverRegionData = await apiClient.get(`api/regionSupplier/Load?supplierID=${regionData.supplierID}&regionID=${regionData.regionID}`);
            this.regionSupplier.update(serverRegionData);
            this.regionSupplier.isNew = !this.regionSupplier.regionID;
        }

        private cancelRegion() {
            this.$bvModal.hide("regionDialogue");
            utils.resetObject(this.regionSupplier);
        }

        private async saveRegion(event: Event) {
            await apiClient.post("/api/regionSupplier/save", this.regionSupplier, event);

            toastr.success("Saved");           
            this.$bvModal.hide("regionDialogue");
            await this.refreshRegions();
        }
    }
