

    import Vue from "vue";
    import Component from "vue-class-component";
    import { Prop, Watch } from "vue-property-decorator";
    import FileUpload from "@/components/FileUpload.vue";
    import fileDownload from "@/stuff/FileDownload";
    import apiClient from "@/stuff/ApiClient";
    import utils from "@/stuff/Utils";
    import { ILookupItem, LookupItem } from "@/model/LookupItem";
    import { Document as ModelDocument } from "@/model/Document";
    import { ISupplierDocument, SupplierDocument } from "@/model/SupplierDocument";
    import { RegionLookupItem } from "@/model/RegionLookupItem";
    import { SupplierDocumentSearchParameters } from "@/model/SupplierDocumentSearchParameters";
    import { DocumentVisibility } from "@/model/Enums";

    @Component({
        components: { FileUpload }
    })
    export default class Documents extends Vue {

        mounted() {
            this.searchParameters.visibilities = [DocumentVisibility.Everyone, DocumentVisibility.Buyers];
            this.searchParameters.supplierID = this.supplierID;
            this.buyerID = +this.$store.state.signedInUser.buyerID;
            this.loadRegionList();
            this.load();
        }

        private isWaiting: boolean = true;

        @Prop({ required: true })
        private supplierID!: number;

        // TODO - use a new search parameters class
        private searchText: string = "";
        private regionID: number = 0;
        private buyerID = 0;

        private readonly searchParameters = new SupplierDocumentSearchParameters();
        private supplierDocumentList: Array<ISupplierDocument> = [];

        get countText(): string {
            return this.supplierDocumentList ? this.supplierDocumentList.length.toString() : "...";
        }

        private regionList: Array<RegionLookupItem> = [];
        private get regionOptions(): Array<ILookupItem> { 
            const filtered = this.regionList.filter(lu => lu.id > 0 && !lu.isArchived && lu.buyerID === this.buyerID);
            return filtered.length === 0 
                ? [ new LookupItem( { id: 0, description:  "(No regions for this buyer!)", isArchived: false }) ]
                : [ new LookupItem( { id: 0, description:  "Any region", isArchived: false }), ...filtered];
        }

        //
        // -- watchers
        //

        @Watch("searchParameters.regionID")
        onRegionIdChanged() {
            this.refreshSupplierDocuments();
        }

        //
        // -- methods
        //

        async loadRegionList() {
            this.regionList = await apiClient.get("api/region/lookups");
        }

        async load() {
            this.supplierDocumentList = [];
            await this.refreshSupplierDocuments();
        }

        async refreshSupplierDocuments() {
            this.isWaiting = true;
            const responseData = await apiClient.post("api/supplierDocument/search", this.searchParameters);
            this.supplierDocumentList = responseData.map((c: ISupplierDocument) => new SupplierDocument(c));
            this.isWaiting = false;
        }

        private iconUrl(document: ModelDocument): string {
            return utils.iconUrl(document);
        }

        async download(doc: ModelDocument) {
            fileDownload.download(doc);
        }

        //
        //  -- fake stuff
        //

        private statusText(): string { 
            const r = Math.floor(Math.random() * 3); 
            return r === 1 ? "In review" : "Active"; 
        }

        private statusWhenText(): string { 
            const r = Math.floor(Math.random() * 30); 
            const date = new Date();
            date.setDate(date.getDate() - r);
            return utils.whenText(date); 
        }

        private expiresText(): string { 
            const r = 20 + Math.floor(Math.random() * 150); 
            const date = new Date();
            date.setDate(date.getDate() + r);
            return utils.dateText(date); 
        }
    }
