
    import Vue from "vue";
    import Component from "vue-class-component";
    import { Prop } from "vue-property-decorator";
    import apiClient from "@/stuff/ApiClient";
    import utils from "@/stuff/Utils";
    import { Supplier as ModelSupplier } from "@/model/Supplier";

    @Component({})
    export default class HmrcAndBanking extends Vue {

        mounted() {
            this.load(this.supplierID);
        }

        @Prop({ required: true })
        private supplierID!: number;

        private readonly supplier = new ModelSupplier();

        async load(supplierID: number) {
            if(supplierID > 0) {
                const serverSupplierData = await apiClient.get(`api/supplier/Load?id=${supplierID}`);
                utils.resetObject(this.supplier);
                // nb - update maps suppliers and contacts too
                this.supplier.update(serverSupplierData);
            }
            else {
                utils.resetObject(this.supplier);
            }
        }
    }
