
    import Vue from "vue";
    import Component from "vue-class-component";
    import { Prop } from "vue-property-decorator";

    import store from "@/store/store";
    import router from "@/router/router";
    import apiClient from "@/stuff/ApiClient";
    import { LookupItem, ILookupItem } from "@/model/LookupItem";
    import { IContact, Contact } from "@/model/Contact";
    import {ContactSearchParameters } from "@/model/ContactSearchParameters";
    import utils from "@/stuff/Utils";
    import { TriState } from "@/model/Enums";

    @Component({})
    export default class Contacts extends Vue {
        
        mounted() {
            this.load();
        }

        //
        // -- properties
        //

        @Prop({ required: true })
        private supplierID!: number;

        private isWaiting: boolean = true;

        contactList: Array<IContact> = [];
        
        //
        // -- methods
        //

        async load() {
            this.contactList = [];
            await this.refreshContacts();
        }

        async refreshContacts() {
            const parameters = new ContactSearchParameters();
            parameters.regionID = 0;
            parameters.supplierID = this.supplierID;
            parameters.isDeleted = TriState.False;

            this.isWaiting = true;
            const responseData = await apiClient.post("api/contact/search", parameters);
            this.contactList = responseData.list.map((c: IContact) => new Contact(c));
            this.$emit("contactCountChanged", responseData.count); // update tab count badge in parent
            this.isWaiting = false;
        }
    }
