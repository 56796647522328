import { render, staticRenderFns } from "./Details.vue?vue&type=template&id=1c46d2e0&scoped=true"
import script from "./Details.vue?vue&type=script&lang=ts"
export * from "./Details.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c46d2e0",
  null
  
)

export default component.exports